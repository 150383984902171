import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProductList from '../components/ProductList.tsx';
import Cart from '../components/Cart.tsx';
import { useTranslation } from 'react-i18next';
import Banner from '../components/Banner.tsx';
import LocalImage3 from '../components/images/banner.png'; // Import your local image

interface Product {
  _id: number;
  name: string;
  price: number;
  image: string;
  images: string[];
  colour: string;
  description: string;
  nutrition?: {
    servingSize: string;
    calories: number;
    totalFat: string;
    totalFatPercent: string;
    saturatedFat: string;
    saturatedFatPercent: string;
    cholesterol: string;
    cholesterolPercent: string;
    sodium: string;
    sodiumPercent: string;
    totalCarbohydrate: string;
    totalCarbohydratePercent: string;
    protein: string;
    proteinPercent: string;
  };
}

interface HomeProps {
  cart: { _id: number; name: string; price: number; quantity: number }[];
  setCart: React.Dispatch<React.SetStateAction<{ _id: number; name: string; price: number; quantity: number }[]>>;
  triggerDrizzleEffect: () => void;
}
const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

const Home: React.FC<HomeProps> = ({ cart, setCart, triggerDrizzleEffect }) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    axios.get(`${API_URL}/api/products`)
      .then(response => {
        setProducts(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the products!', error);
      });
  }, []);

  const handleAddToCart = (id: number) => {
    const product = products.find(product => product._id === id);
    if (product) {
      setCart(prevCart => {
        const existingItem = prevCart.find(item => item._id === product._id);
        if (existingItem) {
          return prevCart.map(item =>
            item._id === product._id
              ? { ...item, quantity: item.quantity + 1 }
              : item
          );
        } else {
          return [...prevCart, { ...product, quantity: 1 }];
        }
      });

      // Trigger the drizzle effect when the item is added to the cart
      triggerDrizzleEffect();
    }
  };

  return (
    <div style={{ marginTop: '60px' }}>
            <Banner image={LocalImage3} />

      <h1 style={{ marginTop: '30px' }}></h1>
      <ProductList products={products} onAddToCart={handleAddToCart} />
    </div>
  );
};

export default Home;
