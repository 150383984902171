// ProductCard.tsx
import React from 'react';
import { Card, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './ProductCard.scss';

interface ProductCardProps {
  _id: number;
  name: string;
  price?: number | null;
  image?: string;
  colour?: string;
  isDiscounted?: boolean;
  discountedPrice?: number | null;
  onAddToCart: () => void;
}

const ProductCard: React.FC<ProductCardProps> = ({
  _id,
  name,
  price,
  image,
  colour = 'gold',
  isDiscounted = false,
  discountedPrice,
  onAddToCart
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/product/${_id}`);
  };

  const defaultImage = "https://i.ibb.co/0fC2XKJ/2024-09-10-22-22-03.jpg";

  return (
    <Card
      className={`product-card ${colour}`}
      hoverable
      cover={<img alt={name} src={image || defaultImage} />}
      onClick={handleCardClick}
    >
      <div className="card-content">
        <Card.Meta 
          title={name} 
          description={
            isDiscounted && discountedPrice != null ? (
              <div className="price-container">
                <span className="discounted-price">€{discountedPrice.toFixed(2)}</span>
                <span className="original-price">€{price?.toFixed(2)}</span>
              </div>
            ) : (
              <span className="standard-price">€{price?.toFixed(2)}</span>
            )
          }
        />

        <Button 
          type="primary" 
          onClick={(e) => {
            e.stopPropagation();
            onAddToCart();
          }} 
          className="add-to-cart"
        >
          {t('addToCart')}
        </Button>
      </div>
    </Card>
  );
};

export default ProductCard;
