import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Carousel, Button, Spin, message, InputNumber } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './ProductDetail.scss';

const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

interface Nutrition {
  servingSize: string;
  calories: number | null;
  totalFat: string | null;
  totalFatPercent: string | null;
  saturatedFat: string | null;
  saturatedFatPercent: string | null;
  cholesterol: string | null;
  cholesterolPercent: string | null;
  sodium: string | null;
  sodiumPercent: string | null;
  totalCarbohydrate: string | null;
  totalCarbohydratePercent: string | null;
  protein: string | null;
  proteinPercent: string | null;
}

interface Product {
  _id: string;
  name: string;
  price: number;
  discountedPrice?: number;
  isDiscounted?: boolean;
  images?: string[];
  image?: string;
  description: string;
  composition?: string;
  nutrition?: Nutrition;
}

interface ProductDetailProps {
  cart: { _id: number; name: string; price: number; quantity: number }[];
  setCart: React.Dispatch<React.SetStateAction<{ _id: number; name: string; price: number; quantity: number }[]>>;
}

const ProductDetail: React.FC<ProductDetailProps> = ({ cart = [], setCart }) => {
  const { productId } = useParams<{ productId: string }>();
  const { t } = useTranslation();
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState<number>(1);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/products/${productId}`)
      .then(response => {
        setProduct(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(`There was an error fetching the product!`, error);
        setLoading(false);
      });
  }, [productId]);

  const handleAddToCart = () => {
    if (!product) return;

    const priceToAdd = product.isDiscounted && product.discountedPrice ? product.discountedPrice : product.price;

    const existingItem = cart.find(item => item._id === product._id);
    if (existingItem) {
      setCart(
        cart.map(item =>
          item._id === product._id ? { ...item, quantity: item.quantity + quantity } : item
        )
      );
    } else {
      setCart([...cart, { _id: product._id, name: product.name, price: priceToAdd, quantity }]);
    }
    message.success(`${product.name} ${t('addedToCart')} (x${quantity})`);
  };

  if (loading) {
    return <Spin size="large" />;
  }

  if (!product) {
    return <div>{t('productNotFound')}</div>;
  }

  const images = product.images && product.images.length > 0
    ? product.images
    : product.image
    ? [product.image]
    : ['https://i.ytimg.com/vi/YzabKuRnESo/hqdefault.jpg'];

  const calculatePerServing = (per100gValue: number | string | null, servingSize: number) => {
    const value = per100gValue ? parseFloat(per100gValue.toString()) : 0;
    return (value * servingSize / 100).toFixed(2);
  };

  const servingSizeInGrams = product.nutrition ? parseFloat(product.nutrition.servingSize) || 0 : 0;

  const renderNutritionRow = (nutrient: string, perServing: string, per100g: string) => {
    return perServing !== '' && per100g !== '' ? (
      <tr>
        <td>{nutrient}</td>
        <td>{perServing}</td>
        <td>{per100g}</td>
      </tr>
    ) : null;
  };

  return (
    <div className="product-detail">
      <h1>{product.name}</h1>

      <div className="product-detail-content">
        <Carousel className="product-images">
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`${product.name} ${index + 1}`} />
            </div>
          ))}
        </Carousel>

        <div className="product-info">
          <div className="product-description-container">
            <h3>{t('productDescription')}</h3>
            <p className="product-description">{product.description}</p>
          </div>
          <div className="product-description-container">
            <h3>{t('composition')}</h3>
            <p className="product-composition">{product.composition}</p>
          </div>

          {product.nutrition && (
            <div className="nutrition-facts">
              <h3>{t('nutritionFacts')}</h3>
              <table className="nutrition-table">
                <thead>
                  <tr>
                    <th>{t('nutrient')}</th>
                    <th>{t('perServing')} ({product.nutrition.servingSize})</th>
                    <th>{t('per100g')}</th>
                  </tr>
                </thead>
                <tbody>
                  {renderNutritionRow(
                    t('calories'),
                    calculatePerServing(product.nutrition.calories, servingSizeInGrams),
                    product.nutrition.calories ? product.nutrition.calories.toString() : ''
                  )}
                  {renderNutritionRow(
                    t('totalFat'),
                    calculatePerServing(product.nutrition.totalFat, servingSizeInGrams) + ` (${calculatePerServing(product.nutrition.totalFatPercent, servingSizeInGrams)}%)`,
                    `${product.nutrition.totalFat || ''} (${product.nutrition.totalFatPercent || ''}%)`
                  )}
                  {renderNutritionRow(
                    t('saturatedFat'),
                    calculatePerServing(product.nutrition.saturatedFat, servingSizeInGrams) + ` (${calculatePerServing(product.nutrition.saturatedFatPercent, servingSizeInGrams)}%)`,
                    `${product.nutrition.saturatedFat || ''} (${product.nutrition.saturatedFatPercent || ''}%)`
                  )}
                  {renderNutritionRow(
                    t('cholesterol'),
                    calculatePerServing(product.nutrition.cholesterol, servingSizeInGrams) + ` (${calculatePerServing(product.nutrition.cholesterolPercent, servingSizeInGrams)}%)`,
                    `${product.nutrition.cholesterol || ''} (${product.nutrition.cholesterolPercent || ''}%)`
                  )}
                  {renderNutritionRow(
                    t('sodium'),
                    calculatePerServing(product.nutrition.sodium, servingSizeInGrams) + ` (${calculatePerServing(product.nutrition.sodiumPercent, servingSizeInGrams)}%)`,
                    `${product.nutrition.sodium || ''} (${product.nutrition.sodiumPercent || ''}%)`
                  )}
                  {renderNutritionRow(
                    t('totalCarbohydrate'),
                    calculatePerServing(product.nutrition.totalCarbohydrate, servingSizeInGrams) + ` (${calculatePerServing(product.nutrition.totalCarbohydratePercent, servingSizeInGrams)}%)`,
                    `${product.nutrition.totalCarbohydrate || ''} (${product.nutrition.totalCarbohydratePercent || ''}%)`
                  )}
                  {renderNutritionRow(
                    t('protein'),
                    calculatePerServing(product.nutrition.protein, servingSizeInGrams) + ` (${calculatePerServing(product.nutrition.proteinPercent, servingSizeInGrams)}%)`,
                    `${product.nutrition.protein || ''} (${product.nutrition.proteinPercent || ''}%)`
                  )}
                </tbody>
              </table>
            </div>
          )}

          {/* Add to Cart Section with Price and Quantity */}
          <div className="add-to-cart-section">
            <div className="price-quantity-container">
              {/* Product Price in Euros (€) */}
              <div className="product-price">
                {product.isDiscounted && product.discountedPrice ? (
                  <>
                    <span className="original-price">€{product.price.toFixed(2)}</span>
                    <span className="discounted-price">€{product.discountedPrice.toFixed(2)}</span>
                  </>
                ) : (
                  <span className="standard-price">€{product.price.toFixed(2)}</span>
                )}
              </div>

              {/* Quantity Selector */}
              <div className="quantity-selector">
                <InputNumber min={1} max={100} value={quantity} onChange={setQuantity} />
              </div>
            </div>

            {/* Add to Cart Button */}
            <Button type="primary" onClick={handleAddToCart} className="add-to-cart">
              {t('addToCart')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
