import React, { useState, useEffect } from 'react';
import './Banner.scss';
import LocalImage3 from '../components/images/banner.png';
import MobileImage from '../components/images/mobileV2.png';

interface BannerProps {
  imageUrl?: string; // Optional prop for custom image URL
}

const Banner: React.FC<BannerProps> = ({ imageUrl }) => {
  const [currentImage, setCurrentImage] = useState(imageUrl || LocalImage3);

  useEffect(() => {
    const updateImageSource = () => {
      if (window.innerWidth <= 768) {
        setCurrentImage(MobileImage); // Use mobile image if screen width is 768px or less
      } else {
        setCurrentImage(imageUrl || LocalImage3); // Use default or provided image for larger screens
      }
    };

    // Set initial image based on window width
    updateImageSource();

    // Update image on window resize
    window.addEventListener('resize', updateImageSource);
    return () => window.removeEventListener('resize', updateImageSource);
  }, [imageUrl]);

  return (
    <div className="banner">
      <img src={currentImage} alt="Banner" className="banner-image" />
    </div>
  );
};

export default Banner;
