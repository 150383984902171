import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Button, CircularProgress, Paper } from '@mui/material';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './PaymentSuccessPage.scss';

const PaymentSuccessPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Using i18n for translations

  const queryParams = new URLSearchParams(location.search);
  const data = queryParams.get('data');
  const ss1 = queryParams.get('ss1');
  const API_URL = process.env.REACT_APP_API_URL || 'https://staging.popcornas.lt';

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      if (data && ss1) {
        try {
          const response = await axios.post(`${API_URL}/api/callback`, { data, ss1 });

          if (response.status === 200) {
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          } else {
            setError(t('paymentErrorIssue'));
            setLoading(false);
          }
        } catch (err) {
          console.error('Error confirming payment:', err);
          setError(t('paymentErrorContactSupport'));
          setLoading(false);
        }
      } else {
        setError(t('paymentErrorMissingData'));
        setLoading(false);
      }
    };

    handlePaymentSuccess();
  }, [data, ss1, API_URL, t]);

  const handleContinueShopping = () => {
    navigate('/');
  };

  return (
    <div className="payment-success-page">
      <Paper elevation={3} className="payment-success-container">
        {loading ? (
          <CircularProgress size={60} className="loading-spinner" />
        ) : error ? (
          <>
            <Typography variant="h4" className="payment-failed">
              {t('paymentFailed')}
            </Typography>
            <Typography variant="body1" className="payment-message">
              {error}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className="payment-button"
              onClick={handleContinueShopping}
            >
              {t('continueShopping')}
            </Button>
          </>
        ) : (
          <>
            <Typography variant="h4" className="payment-success-title">
              {t('paymentSuccessful')}
            </Typography>
            <Typography variant="body1" className="payment-message">
              {t('paymentSuccessMessage')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className="payment-button"
              onClick={handleContinueShopping}
            >
              {t('continueShopping')}
            </Button>
          </>
        )}
      </Paper>
    </div>
  );
};

export default PaymentSuccessPage;
