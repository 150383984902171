// PrivacyPolicy.tsx
import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import './PrivacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="privacy-policy-container">
      <Typography variant="h4" className="privacy-policy-heading">
        {t('privacyPolicyTitle')}
      </Typography>
      
      {/* Existing Privacy Policy Sections */}
      <div className="privacy-policy-content">
        <Typography variant="h6" className="section-title">
          {t('section1Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section1Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section2Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section2Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section3Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section3Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section4Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section4Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section5Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section5Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section6Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section6Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section7Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section7Content')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('section8Title')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('section8Content')}
        </Typography>
      </div>

      {/* New Privacy Policy Sections */}
      <div className="privacy-policy-content">
        <Typography variant="h6" className="section-title">
          {t('introductionTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('introductionContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('dataCollectionTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('dataCollectionContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('purposeTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('purposeContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('legalBasisTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('legalBasisContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('dataRetentionTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('dataRetentionContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('dataSharingTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('dataSharingContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('dataSecurityTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('dataSecurityContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('userRightsTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('userRightsContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('changesTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('changesContent')}
        </Typography>

        <Typography variant="h6" className="section-title">
          {t('contactTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('contactContent')}
        </Typography>
      </div>
      <div className="privacy-policy-content">
        {/* Add the existing new policy content here */}

        {/* Shipping and Receiving Policies Section */}
        <Typography variant="h6" className="section-title">
          {t('shippingAndReceivingTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('shippingContent')}
        </Typography>

        {/* Returns and Exchanges Section */}
        <Typography variant="h6" className="section-title">
          {t('returnsAndExchangesTitle')}
        </Typography>
        <Typography variant="body1" paragraph className="section-content">
          {t('returnsContent')}
        </Typography>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
