import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      discountApplied: "Discount",
        productDescription: "Product Description",
      addToCart: "Add to Cart",
      price: "Price",
      nutritionFacts: "Nutrition Facts",
      servingSize: "Serving Size",
      calories: "Calories",
      totalFat: "Total Fat",
      saturatedFat: "Saturated Fat",
      cholesterol: "Cholesterol",
      sodium: "Sodium",
      totalCarbohydrate: "Total Carbohydrate",
      protein: "Protein",
      yourCart: "Your Cart",
      Products: "Products",
      About_Us: "About Us",
      Contacts: "Contacts",
      Cart: "Cart",
      EnterYourDetails: "Enter Your Details",
      Name: "Name",
      Surname: "Surname",
      Email: "Email",
      PhoneNumber: "Phone Number",
      City: "City",
      Address: "Address",
      Postcode: "Postcode",
      Country: "Country",
      ProceedToPayment: "Proceed to Payment",
      getInTouch: "Get in Touch",
      contactMessage: "We would love to hear from you! Fill out the form below or reach out via email or phone.",
      email: "Email",
      phone: "Phone",
      address: "Address",
      name: "Name",
      message: "Message",
      namePlaceholder: "Enter your name",
      emailPlaceholder: "Enter your email",
      messagePlaceholder: "Enter your message",
      sendMessage: "Send Message",
      messageSent: "Message sent!",
      welcome: "Welcome to Our World of Popcorn",
      discover: "Discover our story, our process, and our passion for making the best popcorn.",
      historyTitle: "Our History",
      historyDescription: "Founded with a love for good popcorn, we bring over a lot of sweat, tears and time to create and bring, simple solution to buy highest level popcorn seasoning.",
      processTitle: "Our Process",
      processDescription: "We accept only best quality Seasoning, with best quality ingredients. We went through many iterations of the product, until we became happy with the outcome.",
      missionTitle: "Our Mission",
      missionDescription: "To bring the Cinema level popcorn seasoning, to your home!",
      followUs: "Want story to continue? Follow us!",
      story: "Our Story Through the Years",
      timeline2023June: "2023 June: Our journey begins with an simple search, where to buy good quality PopCorn seasoning",
      timeline2023Dec: "2023 December: We got frustrated on Holiday season, still haven't found any which would be as good as at Cinemas",
      timeline2024April: "2024 April: We commited to introducing Cinema level PopCorn seasoning - to everyone.",
      timeline2024: "2024 October: After hundreds of hours spent on choosing right lid, colour, getting all certificates, searching for best flavour ones - we did it! We launched our production!",
        "privacyPolicyTitle": "Privacy Policy",
        
        "section1Title": "Submission and Processing of Personal Data",
        "section1Content": "When placing an order in the online store 'Popcornas', the Buyer must provide the following personal data necessary for the proper execution of the order: name, surname, exact delivery address, phone number, and email address.\n\nBy confirming these rules, the Buyer agrees that the personal data provided will be processed by the Seller for the following purposes:\n- sale of goods in the online store 'Popcornas',\n- analysis of the Seller's activities.",
        
        "section2Title": "Use and Protection of Personal Data",
        "section2Content": "The Seller ensures that the Buyer's personal data will not be transferred to third parties, except in the following cases:\n\n- To the Seller's partners providing delivery services or other services related to the proper execution of the order (e.g., couriers, payment service providers),\n- In accordance with the procedures established by the laws of the Republic of Lithuania (e.g., to law enforcement authorities based on legal requirements).\n\nThe Seller undertakes to protect the data provided by the Buyer and use it only for the purposes of providing services, processing orders, and delivering goods. Personal data is processed using secure measures to prevent unauthorized disclosure, destruction, or other unlawful actions.",
        
        "section3Title": "Buyer's Rights and Obligations",
        "section3Content": "The Buyer undertakes to protect and not disclose the login information for the online store to third parties. By placing orders, the Buyer agrees with the terms of purchase and sale of goods and this privacy policy.",
        
        "section4Title": "Data Retention",
        "section4Content": "The Buyer's data related to orders is stored for 2 years from the date of the last order. After this period, the Buyer's personal data is automatically destroyed. The responsible person supervises the automatic data destruction.",
        
        "section5Title": "Buyer's Right to Data Deletion",
        "section5Content": "If the Buyer wishes for the Seller to stop processing their personal data and delete it, they can contact the Seller via email at sales@popcornas.lt. The Seller undertakes to fulfill the Buyer's request within a reasonable period, in compliance with the applicable legal requirements.",
        
        "section6Title": "Dispute Resolution",
        "section6Content": "All disputes or disagreements arising from this privacy policy or the order process are resolved through negotiations. If an agreement cannot be reached, disputes are resolved in accordance with the procedures established by the laws of the Republic of Lithuania.",
        
        "section7Title": "Changes to the Privacy Policy",
        "section7Content": "The Seller reserves the right to change or supplement this privacy policy, taking into account changes in legal regulations or other circumstances. The updated privacy policy takes effect from the day it is published on the online store website. The Buyer is advised to regularly review this policy.",
        
        "section8Title": "Purpose of the Privacy Policy",
        "section8Content": "This privacy policy is designed to protect your personal data and ensure that it is used only for the purposes for which the Buyer has given their consent.",
      
      introductionTitle: "Introduction",
      introductionContent: "Mr popcornas, MB (Registration code: 306946658), located at Didlaukio g. 39-28, LT-08321 Vilnius, is committed to protecting your privacy and ensuring that your personal data is handled securely. This privacy policy outlines how we collect, use, and protect the personal data you provide when using our application, in compliance with the EU General Data Protection Regulation (GDPR).",
      dataCollectionTitle: "Data We Collect",
      dataCollectionContent: "When you use our application and fill out the details form, we collect the following personal information: Name, Surname, Email address, Phone number, City, Address, Postcode, Country, and any other information you provide when contacting us.",
      purposeTitle: "Purpose of Data Collection",
      purposeContent: "We collect your personal data for the following purposes: To process your orders and manage transactions; To provide you with the requested products and services; To communicate with you regarding your orders, updates, and customer service inquiries; To improve our services and customer experience; To comply with legal and regulatory requirements.",
      legalBasisTitle: "Legal Basis for Data Processing",
      legalBasisContent: "Our legal basis for collecting and processing your personal data is based on the following: Performance of a contract – We process your data to fulfill the order you placed with us; Legal obligations – We may be required to process your data to comply with legal and regulatory requirements; Legitimate interests – We may use your data to improve our services, provided this does not override your rights and interests; Consent – Where applicable, we may process your data based on your explicit consent, which you can withdraw at any time.",
      dataRetentionTitle: "Data Retention",
      dataRetentionContent: "We will retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including any legal, accounting, or reporting requirements. Once your data is no longer needed, we will securely delete or anonymize it.",
      dataSharingTitle: "Data Sharing and Third-Party Disclosure",
      dataSharingContent: "We do not sell, trade, or rent your personal data to third parties. However, we may share your data with trusted third parties to facilitate the processing of your order, including payment processing providers, shipping and logistics companies, and IT service providers for system maintenance and hosting. All third-party service providers are required to comply with GDPR and protect your data.",
      dataSecurityTitle: "Data Security",
      dataSecurityContent: "We take the security of your data seriously and have implemented appropriate technical and organizational measures to safeguard your information from unauthorized access, alteration, disclosure, or destruction. These measures include encryption, secure servers, and access controls.",
      userRightsTitle: "Your Rights Under GDPR",
      userRightsContent: "As an EU resident, you have the following rights regarding your personal data: Right to access – You can request a copy of the data we hold about you; Right to rectification – You can ask us to correct any inaccurate or incomplete data; Right to erasure ('Right to be forgotten') – You can request the deletion of your data under certain conditions; Right to restrict processing – You can ask us to limit the processing of your data in specific circumstances; Right to data portability – You have the right to obtain your data in a structured, commonly used, and machine-readable format; Right to object – You can object to the processing of your data for certain purposes; Right to withdraw consent – If processing is based on consent, you can withdraw it at any time.",
      changesTitle: "Changes to This Privacy Policy",
      changesContent: "We may update this privacy policy periodically to reflect changes in our practices or for legal reasons. We will notify you of any significant changes by posting an updated version on our website or via email.",
      contactTitle: "Contact Information",
      contactContent: "If you have any questions, concerns, or requests regarding this privacy policy or your personal data, please contact us at: Email: sales@popcornas.lt or Address: Didlaukio g. 39-28, LT-08321 Vilnius.",
      shippingContent: `
      Delivery Time: All orders are dispatched within 2 working days of order confirmation.
      Delivery Methods: We deliver via Lithuanian Post or courier services.
      Delivery Duration: Delivery time depends on the selected delivery method and can range from 1 to 5 working days.
      Delivery Cost: Shipping fees are calculated based on order weight and delivery location. The exact amount will be indicated before confirming your order.
    `,
    CompanyInfo: "Company Info",

    // Returns and Exchanges Section
    returnsAndExchangesTitle: "Returns and Exchanges",
    returnsContent: `
      Return Period: If you are not satisfied with your purchase, you can return the item within 14 days of receipt.
      Return Conditions: The item must be unused, in its original packaging, with all original labels and accessories.
      Return Process:
      - Contact us via email or phone, providing your order number and the reason for return.
      - We will provide instructions on how to return the item.
      Return Costs: The return shipping costs are covered by the buyer unless the item is defective or incorrect.
      Refund: Refunds will be processed within 14 days of receiving and inspecting the returned item.
    `,
    "ChooseParcelShop": "Choose Parcel Shop",
    "Omniva": "Omniva",
    "Venipak": "Venipak",
    "InvalidEmail": "Please enter a valid email address",
    "InvalidPhone": "Please enter a valid phone number",
    "About Us": "About Us",
    "Menu": "Menu",
    "Select Language": "Select Language",
    "EN": "English (EN)",
    "LT": "Lithuanian (LT)",
      quantity: "Quantity",
      chooseShippingMethod: "Choose Shipping Method",
      parcelShop: "Parcel Shop",
      courier: "Courier",
      total: "Total",
      proceedToCheckout: "Proceed to Checkout",
      // Other existing keys...

      // Additional Cart component-specific translations
      increaseQuantity: "Increase Quantity",
      decreaseQuantity: "Decrease Quantity",
      removeItem: "Remove Item",
      composition: "Ingridients list",
      paymentSuccessful: "Payment Successful!",
      paymentSuccessMessage: "Thank you for your payment. We are processing your order.",
      paymentFailed: "Payment Failed",
      paymentErrorIssue: "There was an issue confirming your payment.",
      paymentErrorContactSupport: "Error confirming payment. Please contact support.",
      paymentErrorMissingData: "Missing payment data.",
      continueShopping: "Continue Shopping",
      "privacy_policy": "Privacy Policy",
      "rules": "Rules",
    
        "rulesTitle": "E-commerce Food Store Rules",
        "generalProvisionsTitle": "General Provisions",
        "generalProvisionsContent": "These rules determine the rights, obligations, and responsibilities of the Buyer and Seller (an online store selling food products) as well as the conditions of the purchase-sale of goods.\n\nBy placing an order in the online store, the Buyer confirms that they have read these rules and agree to abide by them.\n\nThe Seller reserves the right to change or supplement these rules at any time. The latest version of the rules is published on the online store website.",
        
        "purchaseAgreementTitle": "Purchase Agreement Formation",
        "purchaseAgreementContent": "The purchase-sale agreement between the Buyer and the Seller is considered concluded from the moment the Buyer completes the order form in the online store, submits the order, and pays for it.\n\nThe Seller undertakes to deliver the ordered goods, and the Buyer undertakes to accept the goods and pay the agreed price.",
        
        "pricesAndPaymentTitle": "Product Prices and Payment",
        "pricesAndPaymentContent": "Product prices in the online store are indicated in euros (EUR), including VAT.\n\nThe Buyer can pay for the goods using the following methods:\n- bank transfer,\n- online banking,\n- payment card.\n\nThe prices of the goods cannot be changed after the order has been submitted, except in cases where the prices changed due to a technical error or other circumstances beyond the Seller's control (e.g., force majeure). If the Buyer does not agree with the new price, they may cancel the order.",
        
        "deliveryTitle": "Product Delivery",
        "deliveryContent": "The Buyer must provide the correct product delivery address. The Seller is not responsible for non-delivery or delays if it occurred due to an incorrect address or other mistakes made by the Buyer.\n\nProducts are delivered within 1–5 business days after payment is received. Delivery time may vary depending on the chosen delivery method and the Buyer's location.\n\nThe products are delivered by the Seller or its authorized representative (e.g., a courier service). The Buyer undertakes to personally accept the products or ensure that another specified person accepts them.",
        
        "returnsTitle": "Product Returns",
        "returnsContent": "Product returns are carried out in accordance with the laws of the Republic of Lithuania. Food products cannot be returned if they have already been opened, used, or if their original packaging has been damaged, except in cases where the product is of poor quality.\n\nIf the product is of poor quality, the Buyer must inform the e-store “Popcornas” administration about the quality defects within 24 hours. The Buyer can notify the Seller about defective products via email at sales@popcornas.lt. Products must be returned in their original packaging along with all product documents. Before returning the products, the Buyer must contact the Seller via email at sales@popcornas.lt.\n\nThe Buyer is responsible for properly assembling and packaging the product. If the product is not properly assembled or packaged, the Seller has the right not to accept the returned product.\n\nThe Seller collects the defective product at its own expense and replaces it with a quality product or refunds the amount paid for the defective product.\n\nIf the Buyer wishes to receive a refund for defective products, they must submit a request via email to sales@popcornas.lt, indicating the following information: name, surname, order number, the name and quantity of the defective product, and the reason for the return.\n\nThe money will be refunded to the Buyer's bank account from which the payment was made within 5 business days of submitting the request and returning the defective product to the Seller.",
        
        "expirationTitle": "Product Expiration",
        "expirationContent": "The Buyer must pay attention to the expiration date of the products, which is indicated on the packaging. The Seller is responsible for ensuring that the products are sold with a sufficient expiration date that allows the Buyer to safely consume them.\n\nIf the Buyer receives products whose expiration date has expired, they have the right to request a replacement of the products or a refund.",
        
        "buyerObligationsTitle": "Buyer's Obligations",
        "buyerObligationsContent": "The Buyer undertakes to provide accurate information about themselves and their order in the online store and to comply with these rules and the legal requirements of the Republic of Lithuania.",
        
        "dataProtectionTitle": "Personal Data Protection",
        "dataProtectionContent": "The Buyer's personal data is processed in accordance with the online store's privacy policy, which details the procedures for collecting, using, and protecting the data.\n\nThe Buyer agrees that their personal data will be processed solely for the purpose of fulfilling the order.",
        
        "disputeResolutionTitle": "Dispute Resolution",
        "disputeResolutionContent": "All disputes arising from these rules or the performance of the purchase-sale agreement are resolved through negotiations.\n\nIf a dispute cannot be resolved through negotiations, it is settled according to the laws of the Republic of Lithuania by appealing to a competent court."

      

    },
  },
  lt: {
    translation: {
        "privacy_policy": "Privatumo Politika",
  "rules": "Taisyklės",
        productDescription: "Produkto aprašymas", // Add this key for Lithuanian translation
      addToCart: "Į krepšelį",
      price: "Kaina",
      nutritionFacts: "Maistinė vertė",
      servingSize: "Porcijos dydis",
      calories: "Kalorijos",
      totalFat: "Riebalai",
      saturatedFat: "Sotieji riebalai",
      cholesterol: "Cholesterolis",
      sodium: "Natris",
      totalCarbohydrate: "Angliavandeniai",
      protein: "Baltymai",
      yourCart: "Jūsų krepšelis",
      Products: "Produktai",
      About_Us: "Apie Mus",
      Contacts: "Kontaktai",
      Cart: "Krepšelis",
      EnterYourDetails: "Įveskite savo duomenis",
      Name: "Vardas",
      Surname: "Pavardė",
      Email: "El. paštas",
      PhoneNumber: "Telefono numeris",
      City: "Miestas",
      Address: "Adresas",
      Postcode: "Pašto kodas",
      Country: "Šalis",
      ProceedToPayment: "Tęsti apmokėjimą",
      getInTouch: "Susisiekite su mumis",
      contactMessage: "Turite klausimų? Mielai į juos atsakysime.",
      email: "El. paštas",
      phone: "Telefonas",
      address: "Adresas",
      name: "Vardas",
      message: "Žinutė",
      namePlaceholder: "Įveskite savo vardą",
      emailPlaceholder: "Įveskite savo el. paštą",
      messagePlaceholder: "Įveskite savo žinutę",
      sendMessage: "Siųsti žinutę",
      messageSent: "Žinutė išsiųsta!",
      welcome: "Kino teatro magija dabar ir tavo namuose",
      discover: "Spragėsių prieskoniai, kuriuos pamils ir mažas ir didelis",
      historyTitle: "Kokybiški ingridientai",
      historyDescription: 
      "Mūsų prieskoniai gaminami iš aukštos kokybės ingridientų. Daugumoje mūsų produktų pagrindinė sudedamoji dalis yra išrūgų milteliai – aukštos kokybės, natūralus ingredientas, suteikiantis kreminį, sodrų skonį ir švelnų sūrumą. Tai skiriasi nuo daugelio kitų prieskonių rinkoje, kur pirmoje vietoje dažniausiai naudojama druska ar cukrus. Toks pasirinkimas leidžia išvengti perteklinio druskos kiekio, todėl mūsų prieskoniai tampa subalansuoti, švelnūs ir sveikesni. ",    
       processTitle: "Pasirinkimo laisvė",
      processDescription: "Patys esame gero maisto mylėtojai, todėl visada ieškome sprendimų - ką pateikti vakarėlio metu, nusivežti pas draugus ar tiesiog pasirinkti jaukiam vakarui su šeima. Spragėsių prieskonius galima maišyti skirtingomis proporcijomis taip visada atrandant naują skonį. Prieskoniais galima pagardinti ir kitus patiekalus tokius kaip bulvytės, bulvių traškučiai, užtepėlės...",
      missionTitle: "Sveikas užkandis",

      missionDescription:"Palyginus su kitais užkandžiais, spragėsiai pasižymi mažesniu druskos ir riebalų kiekiu. Be to, spragėsiai patys savaime yra pilno grūdo produktas, turintis daug skaidulų, o panaudojus spragėsių prieskonius, jie gali tapti ypatingi be papildomų nereikalingų kalorijų. Taip pat, spragėsiai yra geras polifenolių šaltinis – tai antioksidantai, kurie siejami su geresne kraujotaka ir virškinimo sveikata. ",
      followUs: "Nori, jog ši istorija tęstųsi? Sek mus ir kitose platformose!",
      story: "Mūsų istorija per metus",
      timeline2023June: " 2023 m. - norėdami sukurti tikriausią kino partį namuose, patys norėjome įsigyti spragėsių prieskonių. Deja, tą kartą mūsų paieška buvo nesėkminga.",
      timeline2023Dec: "2023 m. - Po neskėmingų paieškų, nusprendėme, jog laikas Lietuvoje turėti nišinių prieskonių e.parduotuvę.",
      timeline2024April: "2024 m. - atlikus rinkos analizę susiradome partnerius, tiekėjus bei sukūrėme Popcornas ženklą.",
      timeline2024: " 2024 m. - oficialiai pristatėme savo e-parduotuvę rinkoje.",

      "privacyPolicyTitle": "Privatumo Politika",
  
      "section1Title": "Asmens duomenų pateikimas ir tvarkymas",
      "section1Content": "Pirkėjas, užsakydamas prekes el. parduotuvėje „Popcornas“, privalo pateikti šiuos tinkamam prekių užsakymo įvykdymui būtinus asmens duomenis: vardą, pavardę, tikslų prekių pristatymo adresą, telefono numerį ir el. pašto adresą.\n\nPatvirtindamas šias taisykles, Pirkėjas sutinka, kad pateikti asmens duomenys būtų tvarkomi Pardavėjo šiais tikslais:\n- prekių pardavimas el. parduotuvėje „Popcornas“,\n- Pardavėjo veiklos analizė.",
      
      "section2Title": "Asmens duomenų naudojimas ir apsauga",
      "section2Content": "Pardavėjas užtikrina, kad Pirkėjo asmens duomenys nebus perduodami tretiesiems asmenims, išskyrus šiuos atvejus:\n\n- Duomenų valdytojo partneriams, teikiantiems prekių pristatymo paslaugas ar kitus su užsakymo tinkamu įvykdymu susijusius paslaugas (pvz., kurjeriams, mokėjimo paslaugų tiekėjams),\n- Lietuvos Respublikos teisės aktų numatyta tvarka (pvz., teisėsaugos institucijoms pagal galiojančius reikalavimus).\n\nPardavėjas įsipareigoja saugoti Pirkėjo pateiktus duomenis ir naudoti juos tik paslaugų teikimo, užsakymų apdorojimo bei prekių pristatymo tikslais. Asmens duomenys yra tvarkomi naudojant saugias priemones, apsaugančias nuo neteisėto atskleidimo, sunaikinimo ar kitų neteisėtų veiksmų.",
      
      "section3Title": "Pirkėjo teisės ir įsipareigojimai",
      "section3Content": "Pirkėjas įsipareigoja saugoti ir neatskleisti prisijungimo prie el. parduotuvės duomenų trečiosioms šalims. Atlikdamas užsakymus, Pirkėjas sutinka su prekių pirkimo-pardavimo taisyklėmis bei šia privatumo politika.",
      
      "section4Title": "Duomenų saugojimas",
      "section4Content": "Pirkėjo duomenys, susiję su užsakymais, yra saugomi 2 metus nuo paskutinio užsakymo pateikimo. Po šio laikotarpio Pirkėjo asmens duomenys yra automatiškai sunaikinami. Automatinį duomenų sunaikinimą prižiūri atsakingas asmuo.",
      
      "section5Title": "Pirkėjo teisė į asmens duomenų šalinimą",
      "section5Content": "Jei Pirkėjas pageidauja, kad Pardavėjas nebetvarkytų jo asmens duomenų ir juos panaikintų, jis gali kreiptis el. paštu sales@popcornas.lt. Pardavėjas įsipareigoja per protingą laikotarpį įvykdyti Pirkėjo prašymą, laikydamasis galiojančių teisės aktų reikalavimų.",
      
      "section6Title": "Ginčų sprendimas",
      "section6Content": "Visi nesutarimai ar ginčai, kylantys dėl šios privatumo politikos ar prekių užsakymo proceso, sprendžiami derybų būdu. Nepavykus susitarti, ginčai sprendžiami pagal Lietuvos Respublikos teisės aktų numatytą tvarką.",
      
      "section7Title": "Privatumo politikos pakeitimai",
      "section7Content": "Pardavėjas pasilieka teisę keisti ar papildyti šią privatumo politiką, atsižvelgdamas į teisės aktų pokyčius arba kitas aplinkybes. Atnaujinta privatumo politika įsigalioja nuo jos paskelbimo el. parduotuvėje dienos. Pirkėjui rekomenduojama reguliariai peržiūrėti šią politiką.",
      
      "section8Title": "Privatumo politikos tikslas",
      "section8Content": "Ši privatumo politika parengta siekiant apsaugoti Jūsų asmens duomenis ir užtikrinti, kad jie būtų naudojami tik tais tikslais, dėl kurių Pirkėjas davė savo sutikimą.",
    
      introductionTitle: "Įvadas",
      introductionContent: "Mr popcornas, MB (Registracijos kodas: 306946658), įsikūrusi Didlaukio g. 39-28, LT-08321 Vilnius, įsipareigoja saugoti jūsų privatumą ir užtikrinti, kad jūsų asmens duomenys būtų tvarkomi saugiai. Ši privatumo politika aprašo, kaip mes renkame, naudojame ir saugome jūsų pateiktus asmens duomenis, laikantis Europos Sąjungos Bendrojo duomenų apsaugos reglamento (BDAR).",
      dataCollectionTitle: "Surinkti duomenys",
      dataCollectionContent: "Naudojantis mūsų programėle ir pildant duomenų formą, mes renkame šią asmeninę informaciją: Vardas, Pavardė, El. pašto adresas, Telefono numeris, Miestas, Adresas, Pašto kodas, Šalis, bei kita informacija, kurią pateikiate susisiekdami su mumis.",
      purposeTitle: "Duomenų rinkimo tikslas",
      purposeContent: "Mes renkame jūsų asmeninius duomenis šiais tikslais: Užsakymų apdorojimui ir sandorių valdymui; Norint suteikti jums pageidaujamas prekes ir paslaugas; Bendrauti su jumis dėl jūsų užsakymų, atnaujinimų ir klientų aptarnavimo užklausų; Tobulinti mūsų paslaugas ir klientų patirtį; Vykdyti teisėtus ir reglamentuotus reikalavimus.",
      legalBasisTitle: "Teisinis pagrindas duomenų tvarkymui",
      legalBasisContent: "Mūsų teisinis pagrindas asmeninių duomenų rinkimui ir tvarkymui grindžiamas šiais aspektais: Sutarties vykdymas – Mes tvarkome jūsų duomenis, kad įvykdytume jūsų pateiktą užsakymą; Teisiniai įsipareigojimai – Galime būti įpareigoti tvarkyti jūsų duomenis, kad laikytumėmės teisinių ir reglamentuotų reikalavimų; Teisėti interesai – Mes galime naudoti jūsų duomenis siekdami tobulinti savo paslaugas, jei tai nepažeidžia jūsų teisių ir interesų; Sutikimas – Kai taikoma, mes galime tvarkyti jūsų duomenis remiantis jūsų aiškiu sutikimu, kurį galite bet kada atšaukti.",
      dataRetentionTitle: "Duomenų saugojimas",
      dataRetentionContent: "Mes saugosime jūsų asmeninius duomenis tiek laiko, kiek būtina tikslams, dėl kurių jie buvo surinkti, įskaitant bet kokius teisėtus, apskaitos ar ataskaitų reikalavimus. Kai jūsų duomenys nebebus reikalingi, mes juos saugiai ištrinsime arba anonimizuosime.",
      dataSharingTitle: "Duomenų dalijimasis ir atskleidimas",
      dataSharingContent: "Mes neparduodame, nekeičiame ar nuomojame jūsų asmeninių duomenų trečiosioms šalims. Tačiau galime dalytis jūsų duomenimis su patikimais trečiosiomis šalimis, kad būtų įvykdytas jūsų užsakymas, įskaitant mokėjimo apdorojimo tiekėjus, pristatymo ir logistikos įmones, IT paslaugų tiekėjus sistemų priežiūrai ir prieglobai. Visi trečiųjų šalių paslaugų teikėjai privalo laikytis BDAR ir saugoti jūsų duomenis.",
      dataSecurityTitle: "Duomenų saugumas",
      dataSecurityContent: "Mes rimtai vertiname jūsų duomenų saugumą ir įdiegėme tinkamas technines ir organizacines priemones, kad apsaugotume jūsų informaciją nuo neteisėtos prieigos, pakeitimo, atskleidimo ar sunaikinimo. Šios priemonės apima šifravimą, saugius serverius ir prieigos kontrolę.",
      userRightsTitle: "Jūsų teisės pagal BDAR",
      userRightsContent: "Kaip ES gyventojas, jūs turite šias teises dėl savo asmens duomenų: Teisė susipažinti – Galite prašyti kopijos apie jūsų turimus duomenis; Teisė ištaisyti – Galite prašyti, kad mes ištaisytume bet kokius netikslius ar neišsamius duomenis; Teisė ištrinti ('Teisė būti pamirštam') – Galite prašyti ištrinti jūsų duomenis tam tikromis sąlygomis; Teisė apriboti tvarkymą – Galite paprašyti, kad mes apribotume jūsų duomenų tvarkymą tam tikromis aplinkybėmis; Teisė į duomenų perkeliamumą – Jūs turite teisę gauti savo duomenis struktūrizuotu, įprastai naudojamu ir mašininiu būdu skaitomu formatu; Teisė prieštarauti – Galite prieštarauti tam tikroms duomenų tvarkymo veikloms; Teisė atšaukti sutikimą – Jei tvarkymas grindžiamas sutikimu, galite jį bet kada atšaukti.",
      changesTitle: "Pakeitimai šioje privatumo politikoje",
      changesContent: "Mes galime periodiškai atnaujinti šią privatumo politiką, kad atspindėtume mūsų praktikos ar teisinių reikalavimų pokyčius. Apie bet kokius svarbius pakeitimus pranešime, paskelbdami atnaujintą versiją mūsų svetainėje arba el. paštu.",
      contactTitle: "Kontaktinė informacija",
      contactContent: "Jei turite klausimų, rūpesčių ar prašymų dėl šios privatumo politikos ar jūsų asmens duomenų, susisiekite su mumis: El. paštas: sales@popcornas.lt arba Adresas: Didlaukio g. 39-28, LT-08321 Vilnius.",
      shippingAndReceivingTitle: "Shipping and Receiving Policies",
       // Shipping and Receiving Policies Section
       shippingContent: `
         Išsiuntimo laikas: Visus užsakymus išsiunčiame per 2 darbo dienas nuo užsakymo patvirtinimo.
         Pristatymo būdai: Prekes pristatome per Lietuvos paštą arba kurjerių tarnybas.
         Pristatymo trukmė: Pristatymo laikas priklauso nuo pasirinkto pristatymo būdo ir gali svyruoti nuo 1 iki 5 darbo dienų.
         Pristatymo kaina: Pristatymo mokestis apskaičiuojamas pagal užsakymo svorį ir pristatymo vietą. Tiksli suma bus nurodyta prieš patvirtinant užsakymą.
       `,
 
       // Returns and Exchanges Section
       returnsAndExchangesTitle: "Grąžinimas ir keitimas",
       returnsContent: `
         Grąžinimo laikotarpis: Jei nesate patenkinti pirkiniu, galite grąžinti prekę per 14 dienų nuo jos gavimo dienos.
         Grąžinimo sąlygos: Prekė turi būti nenaudota, originalioje pakuotėje ir su visomis originaliomis etiketėmis bei priedais.
         Grąžinimo procesas:
         - Susisiekite su mumis el. paštu arba telefonu, nurodydami užsakymo numerį ir grąžinimo priežastį.
         - Mes suteiksime jums instrukcijas, kaip grąžinti prekę.
         Grąžinimo išlaidos: Grąžinimo siuntimo išlaidas padengia pirkėjas, nebent prekė yra brokuota arba gavote neteisingą prekę.
         Pinigų grąžinimas: Pinigai bus grąžinti per 14 dienų nuo grąžintos prekės gavimo ir patikrinimo.
       `,
        "ChooseParcelShop": "Pasirinkite paštomatą",
        "Omniva": "Omniva",
        "Venipak": "Venipak",
        "InvalidEmail": "Įveskite galiojantį el. pašto adresą",
        "InvalidPhone": "Įveskite galiojantį telefono numerį",
            "About Us": "Apie Mus",
            "Menu": "Meniu",
            "Select Language": "Pasirinkite kalbą",
            "EN": "Anglų (EN)",
            "LT": "Lietuvių (LT)",
            quantity: "Kiekis",
            chooseShippingMethod: "Pasirinkite pristatymo būdą",
            parcelShop: "Paštomatas",
            courier: "Kurjeris",
            total: "Viso",
            proceedToCheckout: "Tęsti pirkimą",
            // Other existing keys...
      
            // Additional Cart component-specific translations
            increaseQuantity: "Padidinti kiekį",
            decreaseQuantity: "Sumažinti kiekį",
            removeItem: "Pašalinti prekę",
            nutrient: "Maistinė vertė",
            perServing: "Per porcija",
            composition: "Sudėtis",
            ChooseCity: "Pasirinkite miestą",
            ChooseParcelLocation: "Pasirinkite paštomatą",
            CompanyInfo: "Rekvizitai",
            paymentSuccessful: "Mokėjimas pavyko!",
            paymentSuccessMessage: "Ačiū už mokėjimą. Mes apdorojame jūsų užsakymą.",
            paymentFailed: "Mokėjimas nepavyko",
            paymentErrorIssue: "Kilo problemų patvirtinant mokėjimą.",
            paymentErrorContactSupport: "Klaida patvirtinant mokėjimą. Prašome susisiekti su pagalba.",
            paymentErrorMissingData: "Trūksta mokėjimo duomenų.",
            continueShopping: "Tęsti apsipirkimą",
            "rulesTitle": "E-parduotuvės taisyklės",
  "generalProvisionsTitle": "Bendrosios nuostatos",
  "generalProvisionsContent": "Šios taisyklės nustato Pirkėjo ir Pardavėjo (el. parduotuvės, prekiaujančios maisto produktais) teises, pareigas, atsakomybę bei prekių pirkimo-pardavimo sąlygas.\n\nPirkėjas, atlikdamas užsakymą el. parduotuvėje, patvirtina, kad susipažino su šiomis taisyklėmis ir sutinka jų laikytis.\n\nPardavėjas pasilieka teisę bet kuriuo metu keisti ar papildyti šias taisykles. Naujausia taisyklių versija skelbiama el. parduotuvės svetainėje.",
  
  "purchaseAgreementTitle": "Pirkimo-pardavimo sutarties sudarymas",
  "purchaseAgreementContent": "Pirkimo-pardavimo sutartis tarp Pirkėjo ir Pardavėjo laikoma sudaryta nuo to momento, kai Pirkėjas užpildo užsakymo formą el. parduotuvėje, pateikia užsakymą ir jį apmoka.\n\nPardavėjas įsipareigoja pristatyti užsakytas prekes, o Pirkėjas įsipareigoja priimti prekes ir sumokėti sutartą kainą.",
  
  "pricesAndPaymentTitle": "Prekių kainos ir atsiskaitymas",
  "pricesAndPaymentContent": "Prekių kainos el. parduotuvėje nurodomos eurais (EUR), įskaitant PVM.\n\nPirkėjas už prekes gali atsiskaityti šiais būdais:\n- banko pavedimu,\n- elektronine bankininkyste,\n- mokėjimo kortele.\n\nPrekių kainos po užsakymo pateikimo negali būti keičiamos, išskyrus atvejus, kai kainos kito dėl techninės klaidos ar kitų nuo Pardavėjo nepriklausančių aplinkybių (pvz., force majeure). Jei tokiu atveju Pirkėjas nesutinka su nauja kaina, jis gali atšaukti užsakymą.",
  
  "deliveryTitle": "Prekių pristatymas",
  "deliveryContent": "Pirkėjas privalo pateikti tikslų prekių pristatymo adresą. Pardavėjas neatsako už prekių nepristatymą ar vėlavimą, jei tai įvyko dėl neteisingai nurodyto adreso ar kitų Pirkėjo klaidų.\n\nPrekės pristatomos per 1–5 darbo dienas, nuo gauto apmokėjimo. Pristatymo terminas gali skirtis priklausomai nuo pasirinkto pristatymo būdo ir Pirkėjo buvimo vietos.\n\nPrekes pristato Pardavėjas arba jo įgaliotas atstovas (pvz., kurjerių tarnyba). Pirkėjas įsipareigoja priimti prekes pats arba užtikrinti, kad prekes priims kitas nurodytas asmuo.",
  
  "returnsTitle": "Prekių grąžinimas",
  "returnsContent": "Prekių grąžinimas vykdomas vadovaujantis Lietuvos Respublikos teisės aktais. Maisto produktai negali būti grąžinami, jei jie jau buvo atidaryti, naudojami ar buvo pažeista jų originali pakuotė, išskyrus atvejus, kai prekė yra nekokybiška.\n\nJei prekė yra nekokybiška, Pirkėjas privalo informuoti el. parduotuvės “Popcornas” administraciją apie kokybės trūkumus per 24 valandas. Apie netinkamos kokybės prekes Pirkėjas gali pranešti el. paštu sales@popcornas.lt. Prekės turi būti grąžinamos originalioje pakuotėje kartu su visais prekės dokumentais. Prieš grąžinant prekes, Pirkėjas privalo susisiekti su Pardavėju el. paštu sales@popcornas.lt.\n\nUž tinkamą prekės sukomplektavimą ir supakavimą atsako Pirkėjas. Jei prekė nėra tinkamai sukomplektuota ar supakuota, Pardavėjas turi teisę nepriimti grąžinamos prekės.\n\nPardavėjas savo lėšomis atsiima netinkamos kokybės prekę ir pakeičia ją į tinkamos kokybės prekę arba grąžina už netinkamos kokybės prekę sumokėtus pinigus.\n\nJei Pirkėjas pageidauja pinigų grąžinimo už netinkamos kokybės prekes, privalo pateikti laisvos formos prašymą el. paštu sales@popcornas.lt, nurodydamas šią informaciją: vardą, pavardę, užsakymo numerį, netinkamos prekės pavadinimą ir kiekį bei grąžinimo priežastį.\n\nPinigai Pirkėjui grąžinami į banko sąskaitą, iš kurios buvo atliktas mokėjimas, per 5 darbo dienas nuo prašymo pateikimo ir netinkamos kokybės prekės grąžinimo Pardavėjui.",
  
  "expirationTitle": "Prekių galiojimo laikas",
  "expirationContent": "Pirkėjas privalo atkreipti dėmesį į prekių galiojimo laiką, kuris nurodytas ant pakuotės. Pardavėjas atsako už tai, kad prekės būtų parduodamos su pakankamu galiojimo laiku, kuris leistų Pirkėjui jas saugiai vartoti.\n\nJei Pirkėjas gavo prekes, kurių galiojimo laikas yra pasibaigęs, jis turi teisę reikalauti prekių pakeitimo arba pinigų grąžinimo.",
  
  "buyerObligationsTitle": "Pirkėjo įsipareigojimai",
  "buyerObligationsContent": "Pirkėjas įsipareigoja el. parduotuvėje pateikti teisingą informaciją apie save ir užsakymą, laikytis šių taisyklių bei Lietuvos Respublikos teisės aktų reikalavimų.",
  
  "dataProtectionTitle": "Asmens duomenų apsauga",
  "dataProtectionContent": "Pirkėjo asmens duomenys yra tvarkomi pagal el. parduotuvės privatumo politiką, kuri detalizuoja duomenų rinkimo, naudojimo ir apsaugos tvarką.\n\nPirkėjas sutinka, kad jo asmens duomenys būtų tvarkomi tik užsakymo įvykdymo tikslais.",
  
  "disputeResolutionTitle": "Ginčų sprendimas",
  "disputeResolutionContent": "Visi ginčai, kylantys dėl šių taisyklių ar pirkimo-pardavimo sutarties vykdymo, sprendžiami derybų būdu.\n\nJei derybų keliu nepavyksta išspręsti ginčo, jis sprendžiamas pagal Lietuvos Respublikos teisės aktus, kreipiantis į kompetentingą teismą.",
  "Bypressingthis": "Paspausdami mygtuką „Užsakyti“, jūs patvirtinate, kad sutinkate su svetainės ",
  ConfirmPayment: "Apmokėti",
  Checkout: "Užsakymas",
  Total: "Viso",
  discountApplied: "Nuolaida"
},
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'lt', // default language
    fallbackLng: 'lt',
    interpolation: {
      escapeValue: false, // React already protects from XSS
    },
  });

export default i18n;
